
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    mixins: [ScreenSize],
    props: {
      invoice: {
        type: Object,
        required: false,
        default: () => {},
      },
      index: {
        type: Number,
        required: false,
        default: () => 0,
      },
    },
    data() {
      return {
        legacyBillingResponse: {},
        check_re1: 0,
        check_call: 0,
        check_re2: 0,
      };
    },
    created() {
      this.check_re1 = this.invoice.check_re1;
      this.check_call = this.invoice.check_call;
      this.check_re2 = this.invoice.check_re2;
    },
    methods: {
      async fetchInvoicePrint(id) {
        const legacyBillingPrintResponse = await this.$store.dispatch(
          'api/legacyBilling/getInvoicesPrint',
          id
        );

        this.legacyBillingPrintResponse = legacyBillingPrintResponse;
        this.downloadItem(id);
      },
      downloadItem(id) {
        const url = window.URL.createObjectURL(
          this.legacyBillingPrintResponse.data.data
        );
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'Rechnung' + id + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
      },
    },
  };
